import { 
    ENTRY_WATCHES_REQUESTED,
    ENTRY_WATCHES_RECEIVED,
    ENTRY_WATCHES_ERROR
} from "../actions/entryWatches";
import RequestState from "../storage/RequestState";
import RequestResult from "../storage/RequestResult";
import {EMPTY_REQUEST_RESULT_INIT_STATE} from "../utils/JsObjectHelper";

/**
* @param {RequestResult} state
* @param {BaseAction|ReceiveEntryTypesAction|ReceiveErrorAction} action
* @return {RequestResult}
*/
export const entryWatches = (state = EMPTY_REQUEST_RESULT_INIT_STATE, action) => {
    switch (action.type) {
        case ENTRY_WATCHES_REQUESTED:
            return new RequestResult(RequestState.getInstanceLoading(), state.data);
        case ENTRY_WATCHES_RECEIVED:
            return new RequestResult(RequestState.getInstanceDone(), action.entryWatches, action.receivedAt);
        case ENTRY_WATCHES_ERROR:
            return new RequestResult(
                RequestState.getInstanceError(
                    action.errorResponseStatusCode,
                    action.errorResponseMessage ? action.errorResponseMessage : null
                ),
                state.getData()
            );
        default:
            return state;
    }
};
