import React from "react";
import "antd/dist/antd.css";
import {
    Drawer,
    Button,
    Row,
    Col,
    Card,
    Dropdown,
    Space,
    Table,
    Menu,
    Tooltip,
    Badge,
} from "antd";
import { PlusOutlined, DeleteOutlined, OrderedListOutlined, SettingOutlined, CloseOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { isUndefined } from "lodash";
import HistoryEntry from "../../containers/pluginPage/widgets/HistoryEntry";
import { isMobile, isTablet } from "../../utils/AdaptiveLayout";
import MyTasks from "../../containers/pluginPage/widgets/MyTasks";
import { fetchNewUserCustomizations } from "../../apicalls/fetchNewUserCustomizations";
import MySavedRequests from "../../containers/pluginPage/widgets/MySavedRequests";
import QueryResult from "../../containers/pluginPage/widgets/QueryResult";
import WatchList from "../../containers/pluginPage/widgets/WatchList";


const cloneDeep = require('lodash.clonedeep');
const definition = [
    { title: "app.userDashboard.widgetHistory", type: 'history' },
    { title: "app.userDashboard.widgetMyTasks", type: 'mytask' },
    { title: "app.userDashboard.widgetMySavedRequests", type: 'mySavedRequest', noSettings: true },
    { title: "app.userDashboard.widgetQueryResult", type: 'queryResult', multiple: true },
    { title: "app.userDashboard.widgetWatchList", type: 'watchList', multiple: true },
];

class PluginPageIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newDashboard: undefined,
            cardListData: { value: [] },
            draverVisible: false,
            controlsSettingsShowArray: [],
        };
    }

    componentDidMount() {
        this.props.onMount();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.myPlugins !== this.props.myPlugins && !isUndefined(this.props.myPlugins) && this.props.setupState.isDone() && this.state.cardListData.value.length === 0) {
            this.setState({ cardListData: this.props.myPlugins })
        }
    }
    onClose = () => {
        this.setState({
            draverVisible: false
        })
    };
    addCard = () => {
        this.setState({
            draverVisible: true,
        })
    }
    newCustomization = () => {
        let settings = { userId: this.props.user.id, type: 'dashboard', name: "dashboard_" + this.props.user.username, value: this.state.cardListData.value }
        if (isUndefined(this.props.myPlugins.id) && isUndefined(this.state.newDashboard)) {
            fetchNewUserCustomizations(settings, (json) => this.setState({ newDashboard: json.id }));
        }
        else {
            settings.id = isUndefined(this.state.newDashboard) ? this.props.myPlugins.id : this.state.newDashboard;
            this.props.updateCustomization(settings)
        }
    }
    showSettings = (i) => {
        let arr = cloneDeep(this.state.controlsSettingsShowArray)
        arr[i] = true;
        this.setState({
            controlsSettingsShowArray: arr,
        })
    }
    closeSettings = (i) => {
        let arr = cloneDeep(this.state.controlsSettingsShowArray)
        arr[i] = false;
        this.setState({
            controlsSettingsShowArray: arr,
        })
    }
    selectionHandler = (record) => {
        let arrCard = cloneDeep(this.state.cardListData)
        let arrShowSettings = cloneDeep(this.state.controlsSettingsShowArray)
        arrShowSettings.push(false)
        arrCard.value.push(record)
        this.setState({
            controlsSettingsShowArray: arrShowSettings,
            cardListData: arrCard,
            draverVisible: false,
        }, () => this.newCustomization())
    }
    deleteCard = (index) => {
        let arrCard = cloneDeep(this.state.cardListData)
        let arrShowSettings = cloneDeep(this.state.controlsSettingsShowArray)
        arrShowSettings.splice(index, 1)
        arrCard.value.splice(index, 1)
        this.setState({
            controlsSettingsShowArray: arrShowSettings,
            cardListData: arrCard,
        }, () => this.newCustomization())
    }
    moveCard = ({ key }, index) => {
        let arr = cloneDeep(this.state.cardListData)
        let temp1 = arr.value.slice(index);
        arr.value.splice(index, 1)
        arr.value.splice(key, 0, temp1[0])
        this.setState({
            cardListData: arr
        }, () => this.newCustomization())
    }
    listPlugin = (data, definition) => {
        let arr = cloneDeep(definition);
        if (!isUndefined(data)) {
            data.value.map((el) => {
                if (isUndefined(el.multiple)) {
                    let i = arr.findIndex((e) => el.type === e.type)
                    arr.splice(i, 1)
                }

            })
        }
        return arr
    }
    newSettings = (index, newSettings) => {
        let arr = cloneDeep(this.state.cardListData);
        arr.value[index].settings = newSettings;
        this.setState({
            cardListData: arr
        }, () => this.newCustomization())
    }


    render() {
        const { t } = this.props;
        const columns = [
            {
                dataIndex: "title",
                key: "title",
                render: (text, record) => {
                    return (<span>
                        {t(text)}
                    </span>)
                },
            },
        ]
        let card = []
        let num = []
        let drawer = null;



        if (this.props.setupState.isDone()) {
            let data = this.listPlugin(this.state.cardListData, definition)
            drawer =
                <>
                    <Drawer
                        title={t("app.userDashboard.titleDrawer")}
                        placement="left"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.draverVisible}
                        extra={
                            <Button type="link" onClick={this.onClose}><CloseOutlined /></Button>
                        }>
                        <Table
                            showHeader={false}
                            pagination={false}
                            columns={columns}
                            dataSource={data}
                            rowKey="type"
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                        this.selectionHandler(record)
                                    },
                                };
                            }}
                        />
                    </Drawer></>;
            this.state.cardListData.value.forEach((el, i) => {
                num.push({ label: i, key: i })
                let menu = (<Menu onClick={(e) => this.moveCard(e, i)} items={num} />)
                let plugin = null;

                switch (el.type) {
                    case 'history':
                        plugin = <HistoryEntry showSettings={this.state.controlsSettingsShowArray[i]} index={i} closeSettings={this.closeSettings} newSettings={this.newSettings} settings={el.settings} />
                        break;
                    case 'mytask':
                        plugin = <MyTasks showSettings={this.state.controlsSettingsShowArray[i]} index={i} closeSettings={this.closeSettings} newSettings={this.newSettings} settings={el.settings} />
                        break;
                    case 'mySavedRequest':
                        plugin = <MySavedRequests showSettings={this.state.controlsSettingsShowArray[i]} index={i} closeSettings={this.closeSettings} newSettings={this.newSettings} settings={el.settings} />
                        break;
                    case 'queryResult':
                        plugin = <QueryResult showSettings={this.state.controlsSettingsShowArray[i]} index={i} closeSettings={this.closeSettings} newSettings={this.newSettings} settings={el.settings} />
                        break;
                    case 'watchList':
                        plugin = <WatchList showSettings={this.state.controlsSettingsShowArray[i]} index={i} closeSettings={this.closeSettings} newSettings={this.newSettings} settings={el.settings} />
                        break;
                    default:
                        break;
                }
                if (plugin) {
                    card.push(
                        <Col key={i} span={isMobile() || isTablet() ? 24 : 12}>
                            <Card
                                key={i}
                                title={<span> <span className="dashboardCount">{i}</span> {!isUndefined(el.settings) && !isUndefined(el.settings.title) ? el.settings.title : t(el.title)}</span>}
                                extra={[
                                    (!isUndefined(el.noSettings) ? null : <Tooltip key='toolip1' placement="top" title={t("app.userDashboard.btnSettings")} >
                                        <Button type="link" onClick={() => this.showSettings(i)} style={{ marginRight: '10px' }}><SettingOutlined style={{ color: '#8c8c8c' }} /></Button>
                                    </Tooltip>),
                                    <Tooltip key='toolip2' placement="top" title={t("app.userDashboard.btnMove")} >
                                        <Dropdown overlay={menu} trigger={['click']} style={{ marginRight: '10px' }}>
                                            <a onClick={(e) => e.preventDefault()}>
                                                <Space>
                                                    <OrderedListOutlined style={{ color: '#8c8c8c' }} />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </Tooltip>,
                                    <Tooltip key='toolip3' placement="top" title={t("app.userDashboard.btnDelete")} >
                                        <Button type="link" onClick={() => this.deleteCard(i)} style={{ marginLeft: '10px' }}><DeleteOutlined style={{ color: '#8c8c8c' }} /></Button>
                                    </Tooltip>,
                                ]}
                                style={{ width: "100%", height: "100%", }}>
                                {plugin}

                            </Card>
                        </Col>
                    )
                }

            })
        }

        return (
            <>
                {drawer}
                < Row gutter={[24, 24]} >
                    {card}

                    <Col span={12}>
                        <Button onClick={this.addCard} style={{ width: "40%", height: "90%", backgroundColor: "#d9d9d9", marginTop: "10px" }} >
                            <PlusOutlined style={{ fontSize: '7vw', color: '#8c8c8c' }} />
                        </Button>
                    </Col>
                </Row >

            </>
        )
    }
}
const PluginPageIndexRouter = withRouter(PluginPageIndex);

export default withTranslation()(PluginPageIndexRouter);
