import React from "react";
import { Button, Drawer, Modal, Space, Tabs, Select, Divider, Popover, Checkbox, Input } from 'antd';
import {
    KeyOutlined,
    DeploymentUnitOutlined,
    InfoCircleOutlined,
    FileOutlined,
    ThunderboltOutlined,
    ClockCircleOutlined,
    MessageOutlined,
    ApartmentOutlined,
    ToolOutlined,
    CloseOutlined,
    TranslationOutlined,
    PaperClipOutlined,
    EyeOutlined,
    EyeTwoTone,
    PlusOutlined
} from '@ant-design/icons';
import { bmcEntryShape } from "../../../shapes/BmcEntryShape";
import EntryTechnicalInfo from "./EntryTechnicalInfo"
import EntryActions from "./EntryActions"
import EntryFriends from "../../../containers/detail/EntryFriends";
import EntryAccess from "../../../containers/detail/EntryAccess";
import EntryVersionList from "../../../containers/detail/EntryVersionList";
import EntryComments from "../../../containers/detail/EntryComments";
import { fetchEntryCommentCount } from '../../../apicalls/fetchEntryCommentCount';
import { isArray, isEmptyValue } from "../../../utils/JsObjectHelper";
import { withTranslation } from 'react-i18next'
import { withRouter } from "react-router-dom";
import EntryFiles from "../../../containers/detail/EntryFiles";
import { fetchEntryFilesCount } from "../../../apicalls/fetchEntryFilesCount";
import { fetchWatchCount } from "../../../apicalls/fetchWatchCount";
import EntryWatchers from "./EntryWatchers";



const { TabPane } = Tabs;
const ButtonGroup = Button.Group;

class EntryToolsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            width: window.innerWidth,
            actionKey: "contextengine",
            commentCount: "-",
            filesCount: "0",
            isWatching: null,
            entryID: '',
            watchCount: "0",
            showPopover: false,
            collectionList: [],
            addNewCollection: false,
            selectedCollection: []
        };
        this.inputRef = React.createRef();
    }


    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);

        fetchEntryCommentCount(this.props.entry.id, this.updateCommentCount);
        this.updateFilesCount();
        this.updateWatchCount();
    };

    componentDidUpdate(prevProps, prevState) {
        const { entryWatches, entry, entryID } = this.props;
        const { data } = entryWatches || {};
        let defaultCollection = [];
        if ((prevProps.entryWatches !== entryWatches || this.state.entryID !== entryID || (prevState.showPopover !== this.state.showPopover && this.state.showPopover)) && isArray(data)) {
            const isWatching = data.find(el => el.entryId === entry.id);
            const uniqueCollections = data.reduce((acc, item) => {
                if (item.properties && item.properties.collection) {
                    item.properties.collection.forEach(col => {
                        if (!acc.includes(col)) {
                            acc.push(col);
                        }
                    });
                }

                return acc;
            }, []);
            if (isWatching) {
                defaultCollection = isWatching.properties.collection;
            }
            this.setState({ isWatching: isWatching, entryID: entryID, collectionList: uniqueCollections, selectedCollection: defaultCollection });
            this.updateWatchCount();
        }
    }
    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    };

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    showDrawer = (actionKeySelected) => {
        let actionKey = this.state.actionKey;

        //lineage smeruje na stranku lineage misto otevirani tools panelu
        if (actionKeySelected && actionKeySelected === "lineage") {
            //this.props.history.push(`/lineage/` + this.props.entry.id);
            //return ;
            window.open("/lineage/" + this.props.entry.id, "_blank");
            return;
        }

        if (actionKeySelected && actionKeySelected !== "tools") {
            actionKey = actionKeySelected;
        }

        this.setState({
            visible: true,
            actionKey: actionKey
        });
    };

    updateCommentCount = (commentCount) => {
        this.setState({
            commentCount: (isEmptyValue(commentCount) ? "0" : commentCount.toString())
        });
    };
    updateWatchCount = () => {
        fetchWatchCount(this.props.entry.id, (watchCount) => this.setState({ watchCount: (isEmptyValue(watchCount) ? "0" : watchCount.toString()) }))
    };
    updateFilesCount = () => {
        fetchEntryFilesCount(this.props.entry.id, (filesCount) => this.setState({ filesCount: (isEmptyValue(filesCount) ? "0" : filesCount.toString()) }));
    };
    onChangeTab = (tabKey) => {
        this.setState({
            actionKey: tabKey
        });
    };

    onClose = () => {
        fetchEntryCommentCount(this.props.entry.id, this.updateCommentCount);
        this.setState({
            visible: false,
        });
    };
    handleShowPopover = () => {
        this.setState({ showPopover: !this.state.showPopover });
    }
    selectCollection = (items) => {
        this.setState({ selectedCollection: items })
    }
    addNewCollection = () => {
        const name = this.inputRef.current.input.value;
        if (name) {
            this.setState(prevState => ({
                collectionList: [...prevState.collectionList, name],
                addNewCollection: false
            }))
        }
        this.inputRef.current.input.value = "";
    }
    activateInput = () => {
        this.setState({ addNewCollection: !this.state.addNewCollection })
    }
    watchEntry = () => {
        this.props.toggleWatchEntry(this.state.selectedCollection);
        this.setState({
            showPopover: false,
            collectionList: [],
            addNewCollection: false,
            selectedCollection: []
        });
    }
    unwatchEntry = () => {
        this.props.toggleWatchEntry();
        this.setState({
            showPopover: false,
            collectionList: [],
            addNewCollection: false,
            selectedCollection: []
        });
    }
    render() {
        const { t } = this.props;
        const { width } = this.state;
        const WIDTH_LIMIT = 800;
        const isMobile = width <= WIDTH_LIMIT;
        let accessPanel = null;
        if (this.props.entry.userEntryAccess === 'EDIT' && this.props.entry.systemType === "folder") {
            accessPanel = <TabPane
                tab={
                    <span>
                        <KeyOutlined />
                        {t('app.entry.tools.tabAccessRightsTitle')}
                    </span>
                }
                key="accessrights"
            >
                <EntryAccess entry={this.props.entry} ></EntryAccess>
            </TabPane>;
        }

        const innerPanel = <Tabs /*defaultActiveKey={this.state.actionKey}*/
            type="card"
            onTabClick={this.onChangeTab}
            activeKey={this.state.actionKey}
            size="small"
        //tabBarGutter={10}
        >
            <TabPane
                tab={
                    <span>
                        <DeploymentUnitOutlined />
                        {t('app.entry.tools.tabContextEngineTitle')}
                    </span>
                }
                key="contextengine"
            >
                <EntryFriends entry={this.props.entry} ></EntryFriends>
            </TabPane>
            <TabPane
                tab={
                    <span>
                        <InfoCircleOutlined />
                        {t('app.entry.tools.tabTechnicalDataTitle')}
                    </span>
                }
                key="techdata"
            >
                <EntryTechnicalInfo entry={this.props.entry} ></EntryTechnicalInfo>
            </TabPane>
            <TabPane
                tab={
                    <span>
                        <FileOutlined />
                        {t('app.entry.tools.tabFileList')}
                    </span>
                }
                key="filelist"
            >
                <EntryFiles entry={this.props.entry} updateFilesCount={this.updateFilesCount}></EntryFiles>
            </TabPane>
            {accessPanel}
            <TabPane
                tab={
                    <span>
                        <ThunderboltOutlined />
                        {t('app.entry.tools.tabActions')}
                    </span>
                }
                key="actions"
            >
                <EntryActions entry={this.props.entry}></EntryActions>
            </TabPane>
            <TabPane
                tab={
                    <span>
                        <ClockCircleOutlined />
                        {t('app.entry.tools.tabVersionsTitle')}
                    </span>
                }
                key="versions"
            >
                <EntryVersionList entry={this.props.entry}></EntryVersionList>
            </TabPane>
            <TabPane
                tab={
                    <span>
                        <EyeOutlined />
                        {t('app.entry.tools.tabWatchersTitle')}
                    </span>
                }
                key="entryWatchers"
            >
                <EntryWatchers id={this.props.entry.id} visible={this.state.visible}></EntryWatchers>
            </TabPane>
            <TabPane
                tab={
                    <span>
                        <MessageOutlined />
                        {t('app.entry.tools.tabCommentsTitle')}
                    </span>
                }
                key="comments"
            >
                <EntryComments entry={this.props.entry}></EntryComments>
            </TabPane>

        </Tabs>;
        let optionsLanguages = Object.keys(this.props.i18n.options.resources).map((el) => { let op = { value: el, label: el.toUpperCase() }; return op })
        let selectLanguage = <Select
            style={{
                width: 60,
            }}
            value={this.props.selectedLanguage}
            placeholder={<TranslationOutlined />}
            onChange={this.props.selectLanguage}
            options={optionsLanguages}
        />
        let lineageButton = null;
        if (this.props.entry.systemType === "object") {
            lineageButton = <Button onClick={() => this.showDrawer("lineage")} key="lineage" name="lineage" icon={<ApartmentOutlined />}>
                <span className="toolsPanelBtnText">{t('app.entry.tools.btnLineage')}</span>
            </Button>;
        };
        let collectionListRadio = null;
        if (this.state.collectionList.length > 0) {
            collectionListRadio = <Checkbox.Group style={{display:'flex',flexDirection:"column",whiteSpace:'pre-line',maxHeight:'220px',overflow:'auto'}} options={this.state.collectionList} defaultValue={this.state.selectedCollection} onChange={this.selectCollection} />
        }
        let content = <div style={{ width: "300px" }}>
            {collectionListRadio}
            <Divider />

            {this.state.addNewCollection ?
                <div >
                    <Input
                        placeholder={t('app.entry.tools.addWatchInputPlaceholder')}
                        ref={this.inputRef}
                    />
                    <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}>
                        <Button type="default" onClick={this.activateInput}>
                        {t('app.entry.tools.btnCancelNewCollection')}
                        </Button>
                        <Button type="primary" style={{ marginLeft: '10px' }} onClick={this.addNewCollection}>
                        {t('app.entry.tools.btnAddNewCollection')}
                        </Button>
                    </div>
                </div> :
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <Button type="text" icon={<PlusOutlined />} onClick={this.activateInput}>
                    {t('app.entry.tools.btnWatchNewCollection')} 
                    </Button>
                    {this.state.isWatching && this.state.selectedCollection.length === 0 ? <Button type="danger" onClick={this.unwatchEntry}>
                        {t('app.entry.tools.btnUnwatch')}  
                    </Button> : <Button type="primary" disabled={this.state.selectedCollection.length === 0} onClick={this.watchEntry}>
                        {this.state.isWatching ? t('app.entry.tools.btnWatchConfirm')  :t('app.entry.tools.btnWatchWatch') } 
                    </Button>}

                </div>}
        </div>

        const panelButtons = <Space size={0}>
            {/* <Button onClick={() => this.props.showOriginal()} style={{ display: this.props.selectedLanguage ? 'block' : "none" }} key="versions" type="link">
                Show original
                { <CloseOutlined /> }
            </Button>
            {selectLanguage} */}
            {lineageButton}
            <Button onClick={() => this.showDrawer("versions")} key="versions" name="versions" title={t('app.entry.tools.tabVersionsTitle')} icon={<ClockCircleOutlined />}>
                <span className="toolsPanelBtnText">{this.props.entry.version}</span>
            </Button>
            <Button onClick={() => this.showDrawer("comments")} key="comments" name="comments" title={t('app.entry.tools.tabCommentsTitle')} icon={<MessageOutlined />}>
                <span className="toolsPanelBtnText">{this.state.commentCount}</span>
            </Button>
            <Button onClick={() => this.showDrawer("filelist")} key="filelist" name="filelist" title={t('app.entry.tools.tabFileList')} icon={<PaperClipOutlined />}>
                <span className="toolsPanelBtnText" >{this.state.filesCount}</span>
            </Button>
            <Popover
                content={content}
                title={<span style={{justifyContent:'space-between',display:'flex', alignItems:'center'}}>{t('app.entry.tools.popoverEntryWatchTitle')}   <Popover content={<div style={{maxWidth:'200px'}}>{t('app.entry.tools.infoTooltip')}</div>}  trigger="hover"><InfoCircleOutlined style={{color:'#3f8dfa'}}/></Popover></span>}
                trigger="click"
                destroyTooltipOnHide
                visible={this.state.showPopover}
                onVisibleChange={this.handleShowPopover}
                placement="bottom"
                style={{ padding: "0" }}
            >
                <Button key="addFavorite" name="addFavorite" title={t('app.entry.tools.btnWatchTooltip')} icon={this.state.isWatching ? <EyeTwoTone style={{ color: 'blue' }} /> : <EyeOutlined />}>
                    <span className="toolsPanelBtnText" style={{ color: this.state.isWatching ? '#1e93ff' : 'black' }} >{this.state.watchCount}</span>
                </Button>
            </Popover>

            <Button onClick={() => this.showDrawer("tools")} key="tools" name="tools" icon={<ToolOutlined />}>
                <span className="toolsPanelBtnText">{t('app.entry.tools.btnToolsPanelText')}</span>
            </Button>
        </Space>

        if (isMobile) {
            return (
                <span>
                    {panelButtons}
                    <Modal
                        title={t('app.entry.tools.toolsPanelTitle')}
                        visible={this.state.visible}
                        width={'100%'}
                        height={'100%'}
                        onOk={this.onClose}
                        onCancel={this.onClose}
                        footer={[
                            <Button key="back" onClick={this.onClose}>
                                {t('app.entry.tools.btnModalReturn')}
                            </Button>,
                        ]}
                    >
                        {innerPanel}
                    </Modal>
                </span>
            );
        } else {
            return (
                <span>
                    {panelButtons}
                    <Drawer
                        title={t('app.entry.tools.toolsPanelTitle')}
                        placement="right"
                        closable={false}
                        onClose={this.onClose}
                        visible={this.state.visible}
                        width={WIDTH_LIMIT}
                        extra={
                            <Button type='link' onClick={this.onClose}><CloseOutlined style={{ color: 'black', fontSize: '24px' }} /></Button>
                        }
                    >
                        {innerPanel}
                    </Drawer>
                </span>
            );
        }
    }
}

EntryToolsPanel.propTypes = {
    entry: bmcEntryShape.isRequired
};

const EntryToolsPanelWithRouter = withRouter(EntryToolsPanel);

export default withTranslation()(EntryToolsPanelWithRouter);