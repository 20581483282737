import React from 'react';
import { Button, Collapse, Drawer, Table,Spin,Tooltip } from 'antd';
import 'antd/dist/antd.css';
import { StarOutlined,CopyOutlined,DeleteOutlined,UnorderedListOutlined } from '@ant-design/icons';
import AdvancedSearchForm from "../../containers/search/AdvancedSearchForm";
import SearchResults from "../../containers/search/SearchResults";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { fetchEntries } from '../../apicalls/fetchEntries';
import { isUndefined } from '../../utils/JsObjectHelper';
import { fetchUserCustomizationById } from '../../apicalls/fetchUserCustomizationById';
import { getBaseDomainName } from '../../utils/UrlHelper';



const { Panel } = Collapse;

class SearchBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            searchedData: [],
            searchedMillis: 0,
            value: undefined,
            collapseActiveKey: "form",
            visible: false,
            propData: null,
            loading: false

        };

        this.handleAdvanceSearch = this.handleAdvanceSearch.bind(this);
    }
    componentDidMount() {
        this.props.onMount();
        if( this.props.searchId && isUndefined(this.state.propData) && this.state.loading === false){
            this.getData()
        }
    }
    handleAdvanceSearch = (values) => {
        this.setState({ collapseActiveKey: "result" });
        let currentTime = new Date().getTime();
        let callbackSearchFnc = (data) => {
            this.setState({ searchedData: data, searchedMillis: new Date().getTime() - currentTime });
        };
        fetchEntries(values.advancedValue,"searchBox", callbackSearchFnc, values.advancedType, null, null, values.dynamicConditions);
    };

    handleKeyChange = (collapseKey) => {
        this.setState({ collapseActiveKey: collapseKey });
    };

    onClose = () => {
        this.setState({ visible: false })
    };

    openDrawer = (event) => {
        this.setState({ visible: true })
        event.stopPropagation();
    };

    selectionHandler = (record) => {
        this.setState({
            propData: record,
            visible: false
        })
    };
    getData = () =>{
        this.setState({loading: true})
        fetchUserCustomizationById(this.props.searchId, (json)=> this.setState({propData: json, loading: false}))
    }
    copyValue = (value) => {
        function fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }

        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(value);
            return;
        }
        navigator.clipboard.writeText(value).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    };

  

    render() {
        const { t } = this.props;
        const columns = [
            {
                dataIndex: "id",
                key: "id",
                width:"5%",
                render: (text, record) => {
                    let url = window.location.hostname
                    return (
                       <Button title={t('app.advancedSearch.copyValueButton')} onClick={() => this.copyValue(`${url}/search/${text}`)}  type="link" ><CopyOutlined style={{fontSize: '16px'}}/></Button>
                   )
                },
            },
            {
                dataIndex: "name",
                key: "name",
                width: "90%",
                render: (text, record) => {
                    return (<span>
                         <Button onClick={() => this.selectionHandler(record)}  type="link" >{text}</Button>
                    </span>)
                },
            },
            {
                dataIndex: "id",
                key: "id",
                width:"5%",
                render: (text, record) => {
                    return (
                       <Button title={t('app.advancedSearch.drawerMySavedQueriesBtnDelete')} onClick={() => this.props.deleteUserCustomization(text)}  type="link" ><DeleteOutlined style={{fontSize: '16px'}}/></Button>
                   )
                },
            },
        ];
     
       
        const serchingTime = (this.state.searchedMillis > 0) ? <div className="App-header-time">{t('app.advancedSearch.searchTime')} {this.state.searchedMillis} ms</div> : <div>&nbsp;</div>;
        let drawer = <Drawer title={t('app.advancedSearch.drawerTitleMySavedRequests')} placement="right" onClose={this.onClose} visible={this.state.visible}>
            <Table
                showHeader={false}
                pagination={false}
                columns={columns}
                dataSource={this.props.requestList}
                rowKey="id"
               
            />
        </Drawer>;

        return <>
            {drawer}
            <Spin spinning={this.state.loading} delay={500}>
            <Collapse accordion activeKey={this.state.collapseActiveKey} onChange={this.handleKeyChange}>
                <Panel header={t('app.advancedSearch.title')} key="form" extra={<Button onClick={this.openDrawer}><UnorderedListOutlined />{t('app.advancedSearch.btnMySavedRequests')} </Button>}>
                    <AdvancedSearchForm onSearch={this.handleAdvanceSearch} propData={this.state.propData} savedQueries={this.props.requestList}/>
                </Panel>
                <Panel header={t('app.advancedSearch.resultTitle')} key="result">
                    <SearchResults data={this.state.searchedData} />

                    {serchingTime}
                </Panel>
            </Collapse>
            </Spin>
        </>
    }
}

const SearchBoxWithRouter = withRouter(SearchBox);

export default withTranslation()(SearchBoxWithRouter);
