import { createBaseOptions, fetchJson, METHOD_POST } from "../utils/SuperAgentFetch";
import { config } from "../config/Config";


/**
 * 
 * @param {String} entryID
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchAddEntryToWatch = (entryID,collectionList, callback) => {

    let body = {properties:{collection:collectionList}};
    /** @type RequestOptions options **/
    let options = createBaseOptions();
    options.method = METHOD_POST;
    options.headers["Content-Type"] = "application/json";
    options["body"] = JSON.stringify(body);

    return fetchJson(`${config.url.API_URL}/entryWatch/custom/entry/${entryID}/item`, options)
        .then(json => {
            if (json.userId && callback) {
                callback('success')
            }
        }
        )
        .catch(error => {
            callback('error')
        });

}


