import React from "react";
import "antd/dist/antd.css";
import {
    Drawer,
    Space,
    Button,
    Select,
    Input,
    Form,
    Table,
    Result,
    Spin,
    Popconfirm
} from "antd";
import { withTranslation } from "react-i18next";
import { fetchUnwatchEntry } from "../../../apicalls/fetchUnwatchEntry";
import EntryTypeTag from "../../controls/EntryTypeTag";
import { getColumnCustomAttributeProps } from "../../../utils/TableHelper";
import { isArray } from "../../../utils/JsObjectHelper";
import { fetchUpdateEntryWatch } from "../../../apicalls/fetchUpdateEntryWatch";
import removeWatch from '../../../images/removeWatch.svg';

class WatchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
        };
    }
    componentDidMount() {
        if (this.props.entryWatches.getData()) {
            let data = [];
            if (this.props.settings && this.props.settings.collection) {
                data = this.props.entryWatches.getData();
                data = data.filter(el =>
                    el.properties &&
                    el.properties.collection &&
                    el.properties.collection.includes(this.props.settings.collection)
                );
            }
            this.setState({ data: data, isLoading: false });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.settings !== this.props.settings || prevProps.entryWatches.getData() !== this.props.entryWatches.getData()) {
            let data = [];
            if (this.props.settings && this.props.settings.collection) {
                data = this.props.entryWatches.getData();
                data = data.filter(el =>
                    el.properties &&
                    el.properties.collection &&
                    el.properties.collection.includes(this.props.settings.collection)
                );
            }
            this.setState({ data: data, isLoading: false });
        }
    };

    saveSettings = (value) => {
        this.props.newSettings(this.props.index, value);
        this.props.closeSettings(this.props.index);
        this.setState({ systemType: null });
    };
    unwatch = (record) => {
        let collectionList = record.properties.collection.filter(element => element !== this.props.settings.collection);
        if (collectionList.length > 0) {
            fetchUpdateEntryWatch(record.entryId, collectionList, () => this.props.refreshList());
        }
        else {
            fetchUnwatchEntry(record.entryId, () => this.props.refreshList());
        }

    }

    getUniqueCollectionsFormatted = (data) => {
        if (isArray(data) && data.length > 0) {
            const allCollections = data.reduce((acc, item) => {
                if (item.properties && item.properties.collection) {
                    acc.push(...item.properties.collection);
                }
                return acc;
            }, []);

            const uniqueCollectionsSet = new Set(allCollections);
            const uniqueCollections = Array.from(uniqueCollectionsSet, collection => ({
                value: collection,
                label: collection
            }));

            return uniqueCollections;
        }
        return []
    }

    render() {
        const { t } = this.props;

        if (this.state.isLoading) {
            return <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spin />
            </div>
        }

        let collectionOptions = this.getUniqueCollectionsFormatted(this.props.entryWatches.getData());
        let drawer =
            <Form
                initialValues={this.props.settings}
                onFinish={this.saveSettings}
            >
                <Drawer
                    height={'100%'}
                    placement="top"
                    closable={false}
                    onClose={() => this.props.closeSettings(this.props.index)}
                    size='small'
                    getContainer={false}
                    visible={this.props.showSettings}
                    footer={
                        <Space>
                            <Button onClick={() => { this.props.closeSettings(this.props.index); this.setState({ systemType: null }); }}>{t("app.userDashboard.watchList.btnCancel")}</Button>
                            <Button htmlType="submit" type="primary" >
                                {t("app.userDashboard.watchList.btnOk")}
                            </Button>
                        </Space>
                    }
                    footerStyle={{ textAlign: 'right' }}
                    style={{
                        position: 'absolute',
                    }}>
                    <Form.Item
                        label={t("app.userDashboard.watchList.labelForSetupInput")}
                        name='title'>
                        <Input showCount />
                    </Form.Item>
                    <Form.Item
                        label={t("app.userDashboard.watchList.setupSelectCollection")}
                        name='collection'>
                        <Select options={collectionOptions}
                            allowClear
                        />
                    </Form.Item>
                </Drawer>
            </Form>

        const columns = [
            {
                title: t("app.userDashboard.watchList.tableAction"),
                key: "action",
                render: (text, record) => {
                    return (
                        <Popconfirm title={t("app.userDashboard.watchList.popconfirmAreYouSure")} onConfirm={() => this.unwatch(record)} okText={t("app.userDashboard.watchList.popconfirmOkText")} cancelText={t("app.userDashboard.watchList.popconfirmCancelText")}>
                            <Button type="link" title={t("app.userDashboard.watchList.tableActionTooltipUnwatch")}><img src={removeWatch} alt="Logo" width="100%" height="100%" /> </Button>
                        </Popconfirm>
                    )
                }
            },
            {
                title: t("app.userDashboard.watchList.tableName"),
                dataIndex: ["entry", "name"],
                key: ["entry", "name"],
                render: (text, record) => {
                    return (<a href={'/entry/' + record.entryId} >{text}</a>)
                }
            },
            {
                title: t("app.userDashboard.watchList.tableEntryType"),
                dataIndex: ["entry", "type"],
                key: ["entry", "type"],
                render: (tag) => (
                    <EntryTypeTag entryTypeName={tag} entryTypeNameList={this.props.objectList.getData()}></EntryTypeTag>

                )
            },
            {
                ...getColumnCustomAttributeProps(
                    ["entry", "updated"],
                    t("app.userDashboard.watchList.tableEntryUpdated"),
                    "date",
                    t
                ),
                onFilter: (value, record) => record.inserted.indexOf(value) === 0
            },
            {
                title: t("app.userDashboard.watchList.modByUserName"),
                dataIndex: ["entry", "modifiedByUserName"],
                key: ["entry", "modifiedByUserName"],
                render: (text, record) => (
                    <a href={'/id2entry/' + record.modifiedByUserId} >{text}</a>

                )
            },

        ];
        let content = <Table
            style={{ minHeight: '300px' }}
            columns={columns}
            dataSource={this.state.data}
            size="small"
            rowKey="id"
            loading={this.props.entryWatches
                .getState()
                .isLoading()}
        />
        if (this.state.data && this.state.data.length === 0) {
            content = <Result
                status="404"
                title={t("app.userDashboard.watchList.resultEmptyList")}
            />
        }

        return (
            <div className="site-drawer-render-in-current-wrapper">
                {drawer}
                {content}
            </div>

        )
    }
}



export default withTranslation()(WatchList);
