import AppPresenter from '../App';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {requestUserLogout} from "../actions/loggedUser";
import {isAdminUser, isSetupUser} from "../utils/AuthHelper";
import { fetchAllBrandingActive } from '../actions/branding/allBrandingActive';
import { fetchEntryWatches } from '../actions/entryWatches';

const mapStateToProps = (state) => ({
    notifications: state.notifications,
    token: (state.loggedUser && state.loggedUser.token) ?  state.loggedUser.token : null,
    loggedUser: (state.loggedUser && state.loggedUser.user) ?  state.loggedUser.user : null,
    isAdmin: isAdminUser(state),
    hasSetupRight: isSetupUser(state)
});

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (keycloak) => {
            dispatch(requestUserLogout());
            keycloak.logout();
        },
        onMount: () => {
            setTimeout(() => {
                dispatch(fetchAllBrandingActive());
                dispatch(fetchEntryWatches());
            }, 500);
        },
    }
};


const App = connect(mapStateToProps, mapDispatchToProps)(AppPresenter);

export default App;

App.propTypes = {
    keycloak: PropTypes.any.isRequired
};