import React from "react";
import {  Spin, Table } from 'antd';
import { withTranslation} from 'react-i18next'
import { fetchEntryWatchers } from "../../../apicalls/fetchEntryWatchers";

class EntryWatchers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading:true,
            watchers:[]
        };
    }

    componentDidMount() {
      fetchEntryWatchers(this.props.id,(data)=>this.setState({watchers:data,isLoading:false}));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.visible !== this.props.visible) {
            fetchEntryWatchers(this.props.id,(data)=>this.setState({watchers:data,isLoading:false}));
        } 
    }
    render() {
        const {t} = this.props;
        const {isLoading,watchers}= this.state;
        if(isLoading){
            return  <div style={{display:'flex',justifyContent:'center'}}>
            <Spin />
          </div>
        }
        
        const columns = [
            {
                title: '',
                dataIndex: ["user","name"],
                key: ["user","id"],
                render: (text,record) => <a href={`/id2entry/${record.user.id}`} target="_blank">{text}</a>,
              }
        ]

        return (
            <>
            <h3>{t('app.entry.tools.watchers.title')}</h3>
            <Table columns={columns} rowKey='id' dataSource={watchers} showHeader={false} />
           </>);
    }
};

export default withTranslation() (EntryWatchers);

