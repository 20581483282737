import { fetchJson } from "../utils/SuperAgentFetch";
import { config } from "../config/Config";


/**
 * 
 * @param {String} entryID
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchUnwatchEntry = (entryID, callback) => {

    /** @type RequestOptions options **/
    let options = {
        method: "DELETE"
    };

    return fetchJson(`${config.url.API_URL}/entryWatch/custom/entry/${entryID}/item`, options)
        .then(json => {
            callback('warning');
        }
        )
        .catch(error => {
            callback('error');
        });

}


