import { fetchJson } from "../utils/SuperAgentFetch";
import ResponseStatusException from "../exceptions/ResponseStatusException";
import { config } from "../config/Config";
import { showError } from "../utils/NotificationsHelper";

export const ENTRY_WATCHES_REQUESTED = "ENTRY_WATCHES_REQUESTED";
/**
 * Action for request of entries.
 *
 * @return {EntryWatchesAction}
 */
export const requestEntryWatches = () => {
  return {
    type: ENTRY_WATCHES_REQUESTED,
  };
};

export const ENTRY_WATCHES_RECEIVED = "ENTRY_WATCHES_RECEIVED";

export const receiveEntryWatches = (entryWatches) => {
  return {
    type: ENTRY_WATCHES_RECEIVED,
    entryWatches: entryWatches,
    receivedAt: Date.now(),
  };
};

export const ENTRY_WATCHES_ERROR = "ENTRY_WATCHES_ERROR";
/**
 *
 * @param {Object} error
 * @param {String} type type of action
 * @param {Number} errorResponseStatusCode
 * @param {String} errorResponseMessage
 * @return {ReceiveEntryWatchesErrorAction}
 */
export const receiveError = (
  error,
  type = ENTRY_WATCHES_ERROR,
  errorResponseStatusCode = null,
  errorResponseMessage = null
) => {
  return {
    type: type,
    error: error,
    errorResponseStatusCode: errorResponseStatusCode,
    errorResponseMessage: errorResponseMessage,
  };
};

/**
 * 
 *
 * @param {boolean} update if is an update request or new loading request
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchEntryWatches = (update = false) => {
  return (dispatch, getState) => {
      if (update) {
        dispatch(requestEntryWatches());
      } else {
        dispatch(requestEntryWatches());
      }

      /** @type RequestOptions options **/
      let options = {
          method: "GET"
      };
    
    options.ignore = {
        key: "fetchEntryWatches",
        maxLifeTime: 60000
    };
    
    return fetchJson(`${config.url.API_URL}/entryWatch/custom/user/items/all`, options, dispatch)
     .then(json => {
        let res = [];
        if (json._embedded && json._embedded.tupleBackedMaps && json._embedded.tupleBackedMaps.length > 0 ) {
            res = json._embedded.tupleBackedMaps;
        }
        dispatch(receiveEntryWatches(res));
    })
    .catch(error => {
        if (error === "Ignoring request") {
            // Optional handling for ignored requests, maybe logging?
        } else if (error instanceof ResponseStatusException) {
            const description = error.response.message ? error.response.message : null;
            dispatch(receiveError(error, ENTRY_WATCHES_ERROR, error.response.status, description));
            console.log(error); // Log the error
        } else {
            dispatch(receiveError(error, ENTRY_WATCHES_ERROR));
            console.log(error); // Log the error
        }
    });
  }
};
