import { fetchJson } from "../utils/SuperAgentFetch";
import { config } from "../config/Config";


/**
 * 
 * @param {String} entryID
 * @return {function(*=, *): Promise<Object | never>}
 */
export const fetchEntryWatchers = (entryID, callback) => {

    /** @type RequestOptions options **/
    let options = {
        method: "GET"
    };

    return fetchJson(`${config.url.API_URL}/entryWatch/custom/entry/${entryID}/items/all`, options)
        .then(json => {
            let res = []
            if (json._embedded && json._embedded.tupleBackedMaps && json._embedded.tupleBackedMaps.length > 0 ) {
                res = json._embedded.tupleBackedMaps;
                console.log(res);
                
            }
             callback(res);
        }
        )
        .catch(error => {
            console.log(error);
            
            // callback('error');
        });

}


