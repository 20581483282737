import EntryViewPresenter from '../../components/detail/EntryView';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {isArray, isUndefined} from "../../utils/JsObjectHelper";
import {fetchObjectList} from "../../actions/objectList";
import { fetchEntryWatches } from '../../actions/entryWatches';


/** @type {RelationsHolder} */
const EMPTY_INCOMING = {type: "incoming", relations: []};
/** @type {RelationsHolder} */
const EMPTY_OUTGOING = {type: "outgoing", relations: []};

/**
 *
 * @param {RequestResult} rr
 * @param {String} type incoming or outgoing
 */
const getRelations = (rr, type) => {
    if(!isUndefined(rr) && rr.getState().isDone()) {
        /** @type {Array<IncomingRelation|OutgoingRelation>} */
        let relations = rr.getData();
        if (!isUndefined(relations) && isArray(relations) && relations.length > 0 &&
            (type === "incoming" || type === "outgoing")
        ) {
            /** @type {RelationsHolder} */
            let result = {
                type: type,
                relations: []
            };
            let property = (type === "incoming") ? "source" : "target";
            for (const relation of relations) {
                /** @type {RelationHolder} */
                let relHolder = result.relations.find(x => x.name === relation.name);
                let relEntry = relation[property];
                relEntry.inserted = relation.inserted;
                if (isUndefined(relHolder)) {
                    relHolder = {name: relation.name, relatedEntries: [relEntry]};
                    result.relations.push(relHolder);
                } else {
                    relHolder.relatedEntries.push(relEntry);
                }
            }
            return result;
        }
    }
    return (type === "incoming") ? EMPTY_INCOMING : EMPTY_OUTGOING;
};


const mapStateToProps = (state, ownProps) => {
    return {
        entryRequestResult: state.entryDetail,
        childrenRequestResult: state.entryDetailChildren,
        incomingRelationsRequestResult: state.entryDetailIncomingRelations,
        outgoingRelationsRequestResult: state.entryDetailOutgoingRelations,
        outgoingRelationsHolder: getRelations(state.entryDetailOutgoingRelations, "outgoing"),
        entryTypesRequestResult: state.objectList,
        entryID : ownProps.match.params.entryID,
        user: state.loggedUser.user,
        entryWatches: state.entryWatches
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: () => {
            dispatch(fetchObjectList());
        },
        updateEntryWatches: () => {
			dispatch(fetchEntryWatches(true));
		},
        // onUnmount: () => {
        //
        // },
    }
};

const EntryView = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryViewPresenter);

export default withRouter(EntryView);