import WatchListPresenter from "../../../components/pluginPage/widgets/WatchList";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchEntryWatches } from "../../../actions/entryWatches";



const mapStateToProps = (state, ownProps) => {
	return {
        entryWatches: state.entryWatches,
        objectList: state.objectList
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		refreshList:()=>{
            dispatch(fetchEntryWatches(true));
        }
	
	};
};

const WatchList = connect(
	mapStateToProps,
	mapDispatchToProps
)(WatchListPresenter);

export default withRouter(WatchList);
